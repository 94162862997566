import { ref, Ref } from "vue";
import { UserLogin } from "../pages/users/types";
import axios from "axios";

const userAuth: Ref<UserLogin | undefined> = ref();
const tokenAuth: Ref<string | undefined> = ref();
const roleDataAuth: Ref<string[]> = ref([]);
const isLoginFinish = ref(false);

const setAuthContext = (user: UserLogin, token: string, roleData: string[]) => {
	userAuth.value = user;
	tokenAuth.value = token;
	roleDataAuth.value = roleData;

	axios.defaults.headers.common["Authorization"] = token;
};

const buildInfoByEnv = () => {
	const redirectAuth = import.meta.env.VITE_APP_AUTH_URL;
	const authBaseUrl = import.meta.env.VITE_APP_BASE_URL_GATEWAY;

	return {
		redirectAuth,
		authBaseUrl,
	};
};

export function useAuth() {
	return {
		setAuthContext,
		getUser: () => userAuth.value,
		buildInfoByEnv,
		isLoginFinish
	};
}
